$font-family: 'Inter',
sans-serif;
$white: #ffffff;
$black: #333333;
$light-gray: #f7f7f7;
$dark-gray: #4B4B52;
$testimonial-card-bg: #44444C;
$light-blue: #F1F5FF;
$dark-blue: rgba(151, 71, 255, 1);
$text-gray: rgba(209, 213, 218, 1);
$active-color: #9747FF;
$default-font-color:#212121;
$primary:#4370E5;


*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: $font-family;
  color: $default-font-color;
  background: linear-gradient(180deg, rgba(189, 223, 255, 0.27) 0%, $white 100%);
}

.main-content{
  max-width: 1280px;
}

.cursor-pointer{
  cursor: pointer;
}

.no-border {
  border: none !important;
}


.home {
  min-height: 100vh;
  background-color: linear-gradient(180deg, rgba(189, 223, 255, 0.27) 0%, $white 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.trusted {
  background-color: $white;
  color: $black;

  .title {
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    margin-bottom: 40px;
    padding: 16px 0;
  }

  .trusted-section {
    padding: 64px 0;
    text-align: center;
  }
  
  .trusted-container {
    margin: 0 auto;
    max-width: 1280px;
  }
  
  .logo-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .logo-container {
    flex: 1 1 235px;
    max-width: 230px;
    margin-bottom: 50px;
  }
  
  .logo-image {
    // width: 100%;
    height: auto;
  }

}

.features {
  background-color: $light-gray;
  color: $default-font-color;

  .parallax-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .parallax-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $light-gray;
  }
  .feature-item {
    margin-top:30px;
    .small-title {
      width: fit-content;
      color: $primary;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      text-align: left;
      padding: 10px 12px 10px 12px;
      border-radius: 41px;
      background: #F0F4FF;
  
  
    }
  
    .title {
      color: $default-font-color;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      text-align: left;
    }
  
    .description {
      color: #4F4F4F;
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;
  
    }
  }
}

.testimonials {
  background-image: url('./images/testimonials-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $dark-gray;
  color: $white;
}


.w3-card {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pricing {
  background-color: $white;
  color: $black;
  .heading {
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    margin-bottom: 82px;
  }

  .worth-it {
    top: -22px;
    border-radius: 37px;
    padding: 8px 24px;
    background: #E8E02A;
    width: fit-content;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;


  }

  .w3-card {
    position: relative;
    border: 1px solid #D1D5DA;
  }

  .active-card {
    position: relative;
    margin-top: -12px;
    color: $white;
    background: $active-color;
  }

  .sub-heading {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    padding-left: 10px;

  }

  .price-type {
    font-size: 36px;
    font-weight: 600;
    line-height: 38px;
    text-align: left;

    &.free-trial {
      color: #5D5C5C;

    }

    &.single-owner {
      color: #FFFFFFCC;
    }

    &.multi-owner {

      background-color: #9747FF;
      background-image: linear-gradient(90deg, #9747FF 0%, #681DC9 100%);
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;

    }
  }

  .price-amount {
    font-size: 45px;
    font-weight: 700;
    line-height: 72.61px;
    text-align: left;

  }

  .price-duration {
    font-size: 32px;
    font-weight: 700;
    line-height: 72.61px;
    text-align: left;
    vertical-align: super;
  }

  .price-feature-item {
    color: $default-font-color;
    font-size: 16px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;

    &.active {
      color: $white;
    }

  }

  .price-btn-container {
    .w3-button {
      &.active,
      &:hover {
        color: $primary !important;
      }
    }
  }
}

footer {
  background: $light-blue;
  border-radius: 0px 0px 24px 24px;

  .nav-link-heading {
    color: #595A60;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }

  .w3-ul li {
    border: none;
  }

  a {
    color: $default-font-color;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

    &:hover {
      text-decoration: underline;
    }
  }
}

.top-nav {
  z-index: 999;
  background: $light-blue;

  img {
    object-fit: contain;
  }

  a,
  a:hover {
    color: $default-font-color;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;

    &.w3-button:hover {
      color: $default-font-color;
    }

    &.active {
      color: $primary !important;
    }
  }
}

.demo {
  .w3-input {
    border: 1px solid #BCBED3;
    padding: 12px 115px 12px 16px;
    width: 100%;
    height: 55px;
    outline: none;
  }

  .demo-btn {
    position: absolute;
    top: -12px;
    right: 4px;
  }
}

.footer-section {
  background: $dark-blue;

  .foot-text {
    color: $white;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
  }
}


.book-demo-card {
  .top-text {
    color: $active-color;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

    span {
      color: $default-font-color;
    }
  }

  .bottom-text {
    color: #585858;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
  }

  .social-icons {
    display: inline-flex;
    align-items: center;
    gap: 32px;
    justify-content: flex-start;

    img {
      object-fit: contain;
    }
  }
}


.primary-btn,
.primary-btn:hover {

  font-size: 18px;
  font-weight: 600;
  line-height: 31px;
  text-align: center;

  color: $white !important;
  background-color: $primary !important;
}

.secondary-btn,
.secondary-btn:hover {
  color: $primary;
  font-size: 18px;
  font-weight: 600;
  line-height: 31px;
  text-align: center;
  border: 1px solid $primary;
  background: $white !important;
}

.small-title {
  color: $primary;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: center;

}

.jumbo-text {
  color: $default-font-color;
  font-weight: 600;
  text-align: center;

  span {
    color: $primary;
    font-weight: 600;
    text-align: center;
  }

}

.jumbo-desc {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;

}

.testimonials-section {
  .small-title {
    width: fit-content;
    background: #4A4A54;
    padding: 10px 12px;
    border-radius: 41px;
    gap: 10px;

    color: #BDBDC9;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    margin-bottom: 45px;
  }

  .title {
    font-size: 28px;
    font-weight: 500;
    line-height: 38px;
    text-align: left;
  }

  .description {
    color: #DBDBE4;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;

  }

  .w3-card {
    background: $testimonial-card-bg;

    .title {
      color: $white;
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      opacity: 70%;
    }

    .description {
      color: #E4E4E4;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      opacity: 60%;

    }

    .person-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      opacity: 0.5;
      margin-bottom: 16px;

      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
      }

      .name-container {
        display: flex;
        flex-direction: column;

        .name {
          color: $white;
          font-size: 18px;
          font-weight: 500;
          line-height: 32px;
          text-align: left;
        }

        .designation {
          color: $white;
          font-size: 16px;
          font-weight: 400;
          line-height: 32px;
          text-align: left;
        }
      }
    }

    &:hover {
      .title {
        opacity: 100%;
      }

      .description {
        opacity: 100%;
      }

      .person-container {
        opacity: 1;
      }
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .animate-card-border {
    position: relative;
    z-index: 0;
    overflow: hidden;
    padding: 0.5rem;

    &:hover::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background-color: $testimonial-card-bg;
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      background-image: linear-gradient(124.34deg, #8D8DF7 -4.04%, #1D1DD4 102.86%);
      animation: rotate 4.5s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 2px;
      top: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background: $testimonial-card-bg;
      border-radius: 9px;
    }

  }

  .testimonials-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .testimonial-card {
    display: flex;
    flex: 1 1 30%; 
    min-width: 300px;
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .person-container {
    display: flex;
    align-items: center;
    margin-top: auto; 
  }
  
  .person-container img {
    margin-right: 10px;
  }

}


.slideshow-container {
  width: 100%; 
  // height: 650px; 
  position: relative;
}
.slideshow {
  position: relative;
  width: 100%;
  height: 100%;

  .card-container {
    margin-top: 60px;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .card {
    width: 100%;
    max-width: 100%; 
    max-height: 650px; 
    border-radius: 16px;
    box-shadow: 3px 2px 10px -5px rgba(0, 0, 0, 0.51);
    background-color: #fff;
    position: absolute;
    top: 25px;
    left: 50%;
    transform:  translateX(-50%) translateY(-50px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    transition: bottom 0.8s ease, opacity 0.5s ease;
    transition: width 0.8s ease;
  }

  .card img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
  }

  .arrow svg {
    width: 10px;
    height: 20px;
  }

  .arrow-right {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);
    cursor: pointer;
  }

  .arrow-left {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

/* styles for mobile screens */
@media (max-width: 767px) {

  .w3-sidebar {
    background: $light-blue;
  }

  .drawer-btn {
    font-size: xx-large;

    &.hamburger {
      margin-top: -15px;
    }
  }

  .primary-btn, .primary-btn:hover {
    font-size: 15px;
  }

  .jumbo-text {
    font-size: 35px;
    line-height: 38.82px;

    span {
      font-size: 35px;
      line-height: 38.82px;
    }
  }

  .slideshow-container {
    height: 38vh;
  }

  .pricing {
    .active-card {
      margin-top: 20px;
      transform: none;
    }
  }

  .copy-right{
    text-align: center;
  }

}

/* styles for tablet screens */
@media (min-width: 768px) and (max-width: 991px) {
  .primary-btn, .primary-btn:hover {
    font-size: 17.5px;
  }

  .jumbo-text {
    font-size: 45px;
    line-height: 48.82px;

    span {
      font-size: 45px;
      line-height: 48.82px;
    }
  }

  .slideshow-container {
    height: 44vh;
  }

  .copy-right{
    text-align: right;
  }
}


/* styles for desktop and laptop screens */
@media (min-width: 992px) {

  .primary-btn, .primary-btn:hover {
    font-size: 18px;
  }

  .jumbo-text {
    font-size: 60px;
    line-height: 96.82px;

    span {
      font-size: 60px;
      line-height: 96.82px;
    }
  }

  .slideshow-container {
    height: 88vh;
  }

  .pricing {
    .active-card {
      transform: scale(1.05);
    }
  }
}